import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Shop from "../components/shop.jsx"

class TrackBuilder extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { };

  }
  
  render() {

    return (
    <div>
      <Shop header={{
            title: "Hot Wheels Track Builder",
            text: "Hot Wheels Track Builder ist eine Reihe von Spielsets und Bahnen für Kinder die <b>mindestestens 6 Jahre alt</b> sind. Im Gegensatz zu den Bahnen Action und City bestehen die Sets von Track Builder aus viel mehr einzelnen Bauteilen. Diese Mehrzweckteile können in nahezu endlosen Kombinationen verwendet werden, um immer neue Bahnen zu bauen und der Kreativität freien Lauf zu lassen. Sie können die verschiedenen Reihen von Sets auch beliebig miteinander mischen, da alle Hot Wheels sind miteinander kompatibel sind.",
            defaultCategory: "Track Builder"
        }} location={this.props.location} />
    </div>)
  }
}


export default TrackBuilder